class FormSender{
    constructor(data, callback){
        this.data = data;
        this.callback = callback;
    }


    SendData(){
        return fetch('/api/form/', this.settings)
            .then(r => r.json())
            .then(j => this.callback(j));
    }

    get settings(){
        return{
            body: JSON.stringify(this.data),
            method: "POST",
            headers:{
                "Content-Type": "application/json"
            }
        }
    }
}

export default FormSender;