import Validate from './Validate';


class ValidateEmpty extends Validate{
    constructor(data){
        super(data);
    }

    get IsValidate(){
        return this.data.trim().length !== 0;
    }
}

export default ValidateEmpty;