class Helper {
    get uniqid() {
        return Math.round((Math.random() * 10).toFixed(8) * 10000000).toString();
    }


    isEmpty(value) {
        return value === undefined ||
            value === null ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0)
    }
}


const instance = new Helper();
Object.freeze(instance);

export default instance;