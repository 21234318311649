import $ from 'jquery';
import slick from 'slick-carousel';

class Slider {
    constructor(containerSelector) {
        this.targetSliderSelector = `${containerSelector} .slider-wrapper`;
        this.prevBtnSelector = `${containerSelector} .arrow-prev`;
        this.nextBtnSelector = `${containerSelector} .arrow-next`;
        this.targetSlider = null;
        this.prevBtn = null;
        this.nextBtn = null;
    }


    Init() {
        this.targetSlider = $(this.targetSliderSelector);
        this.prevBtn = $(this.prevBtnSelector);
        this.nextBtn = $(this.nextBtnSelector);

        this.targetSlider.slick(this.settings);
    }

    get settings() {
        return {
            infinite: false,
            speed: 300,
            prevArrow: this.prevBtn,
            nextArrow: this.nextBtn
        }
    }
}


export default Slider;
