import ProductImageDetail from './ProductImageDetail';
import ProductImagePopup from './ProductImagePopup';
import _ from 'lodash';
import $ from 'jquery';


class ProductImageBuilder{
    constructor(items){
        let popup = this.InitProdcutPopup(items);
        this.InitProdcutDetail(_.cloneDeep(items), popup);    
    }

    InitProdcutDetail(items, popup){
        let currentImage = $("section.product-detail-page .image-container .img-wrapper img");
        let listElements = $("section.product-detail-page .image-container .img-nav-list");
        return new ProductImageDetail(currentImage, listElements, items, popup);
    }

    InitProdcutPopup(items){
        let currentImage = $(".off-product-image .current-image .img-wrapper img");
        let listElements = $(".off-product-image .off-product-image-list");
        return new ProductImagePopup(currentImage, listElements, items);
    }
}

export default ProductImageBuilder;