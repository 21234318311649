import FormPopup from './FormPopup';
import $ from 'jquery';
import ValidateEmpty from './validateForm/ValidateEmpty';
import ValidatePhone from './validateForm/ValidatePhone';
import ValidateEmail from './validateForm/ValidateEmail';
import Validate from './validateForm/Validate';

class FormServiceItem extends FormPopup {
  constructor () {
    super();
    this.popupSelector = '.service-popup';
    this.container = $('.off-canvas-bg.service-popup');
    this.formType = 'service';
  }

  get inputs () {
    return [
      {
        id: 'name_service_popup',
        name: 'Имя',
        type: 'name',
        validateItems: [
          {
            validate: ValidateEmpty,
            validateEvent: 'change',
            errorMessage: 'Проверьте введённые данные!',
          }
        ]
      },
      {
        id: 'phone_service_popup',
        name: 'Телефон',
        type: 'phone',
        validateItems: [
          {
            validate: ValidatePhone,
            validateEvent: 'change',
            errorMessage: 'Проверьте номер телефона',
          }
        ]
      },
      {
        id: 'comment_service_popup',
        name: 'Имя',
        type: 'comment',
        validateItems: [
          {
            validateEvent: 'change',
            validate: ValidateEmpty,
          }
        ]
      },
      {
        id: 'email_service_popup',
        name: 'Почта',
        type: 'email',
        validateItems: [
          {
            validate: ValidateEmail,
            errorMessage: 'Проверьте почту',
            validateEvent: 'change',
          }
        ]
      }
    ];
  }

  get selectorsShowPopup () {
    return ['.open-service-form-popup'];
  }

  get otherData () {
    let result = [];
    $('.cost-solution').find('.sigle-checkbox-wrapper input:checked').each((i, e) => {
      let name = $(e).parents('.sigle-checkbox-wrapper').find('.name').text();
      result.push(name);
    });
    return result;
  }
}

export default FormServiceItem;
