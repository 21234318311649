import $ from 'jquery';

class ProductSort{
    constructor(selector){
        this.select = $(selector);
    }

    Init(){
        if(this.select.length === 0){
            return;
        }
        this.select.on('change', e => this.ReloadPage(e.target.value));
    }


    ReloadPage(value){
        let ar = value.split('||');
        let sort = ar[0];
        let by = ar[1];

        let href = window.location.href;
        let query = window.location.search;
        let urlBuilder = new URLSearchParams(query);
        urlBuilder.set('SORT', sort);
        urlBuilder.set('ORDER_BY', by);
        if(query == ""){
            href += '?' + urlBuilder.toString();
        }
        else{
            href = href.replace(query, '?' + urlBuilder.toString());
        }
        
        window.location.href = href;
    }
}


export default new ProductSort("section.product-section .sort-wrapper select");