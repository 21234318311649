import $ from 'jquery';
import OffCanvasBg from './OffCanvasBg';

class FilterPopup{

    constructor(){
        this.container = $(".off-canvas-bg.filter-popup");
        this.popup = new OffCanvasBg(".filter-popup");
    }


    Init(){
        this.container.find('.close-icon').on('click', () => this.popup.Close());
        this.openSelector.forEach(e => $(e).on('click', () => this.popup.Open()));
    }


    get openSelector(){
        return ['.open-filter-popup'];
    }
}


export default new FilterPopup();