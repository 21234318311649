import FormSelect from './FormSelect';
import $ from 'jquery';

class FormSelectBuilder{
    constructor(){
        this.class = 'form-select-list'
        this.selector = `.${this.class}`;
        this.items = [];
    }

    Init(){
        $(this.selector).each((i, e) => {
            let select = new FormSelect($(e));
            select.Init();
            this.items.push(select);
        });

        this.InitEvents();
    }

    InitEvents(){
        $(document).on('click', this.ClickHandler.bind(this));
    }


    ClickHandler(e){
        let target = $(e.target); 
        let jNode = null;

        if(target.hasClass(this.class))
            jNode = target;
        else if(target.parents(this.selector).length !== 0)
            jNode = target.parents(this.selector);
        
        if(jNode !== null){
            this.items.forEach(item => {
                if(item.Equal(jNode))
                    item.ClickHandler(target);
                else
                    item.Close();
            });
        }
        else{
            this.CloseAll();
        }
        
    }

    CloseAll(){
        this.items.forEach(e => e.Close());
    }
}


export default new FormSelectBuilder();