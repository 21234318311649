import $ from 'jquery';

class Metrika{
    constructor(){

    }


    Init(){
        $('body').on( 'click', '[href^="tel:"]', () => this.goals('click-tel'));
        $('body').on( 'click', '[href^="mailto:"]', () => this.goals('click-mailto'));
        $(document).on('form.success.common', () => this.goals('send-request'));
        $(document).on('form.success.product', () => this.goals('send-tov-request'));
        $(document).on('form.success.footer', () => this.goals('send-question'));
    }

    goals($v){
        yaCounter39739895.reachGoal($v);
        gtag('event', 'name', { 'event_category': 'rkl', 'event_action': $v, });
    }
}


export default new Metrika();