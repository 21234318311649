import $ from 'jquery';
import OffCanvas from './OffCanvas';

class MobileMenu extends OffCanvas{
    constructor(){
        super(".menu-mobile-wrapper");
        this.delayHide = 300;
        this.isBlock = false;
        this.isOpen = false;
    }


    Open(){
        if(!this.isBlock){
            $(this.containerSelector).addClass("open");
            this.isOpen = true;
            this.FixedContent();
            $(document).trigger("mobileMenuOpen");
        }
    }

    Close(){
        if(!this.isBlock){
            $(this.containerSelector).removeClass("open");
            this.isBlock = true;
            this.UnFixedContent();
            $(document).trigger("mobileMenuClose");
           
            setTimeout(() => {
                this.isBlock = false;
                this.isOpen = false;
            }, this.delayHide);
        }
    }

    Toggle(){
        if(this.isOpen){
            this.Close();
        }
        else{
            this.Open();
        }
    }
}


let menu = null;

if(menu === null){
    menu = new MobileMenu();
}

export default menu;