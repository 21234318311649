import PdfViewer from './PdfViewer';
import $ from 'jquery';
import OffCanvasBg from './OffCanvasBg';

class PdfProductSpecification{
    constructor(url){
        this.selectorCanvas = ".pdf-container-product .canvas-wrapper";
        this.containerCanvas = $(this.selectorCanvas);
        this.url = url;
        if(this.containerCanvas.length == 0){
            return;
        }
        this.Init();
        this.InitEvents();
    }

    Init(){
        this.pdfViewer = new PdfViewer(this.containerCanvas);
        this.pdfViewer.SetUrl(this.url);
        this.pdfViewer.EventPageLoaded = this.OnPageLoaded;
        this.pdfViewer.EventPageRendered = this.OnPageRendered;
        this.pdfViewer.EventPdfLoaded = this.OnPdfLoaded;
        this.popup = new OffCanvasBg(".pdf-popup");
    }

    InitEvents(){
        $('.pdf-container-product .arrow.prev').on('click', () => this.pdfViewer.PrevPage());
        $('.pdf-container-product .arrow.next').on('click', () => this.pdfViewer.NextPage());
        $('.pdf-container-product .maximize').on('click', () => this.OpenPopup());
        $('.pdf-container-product .close-icon').on('click', () => this.ClosePopup());
    }

    OnPageLoaded(num){
        $('.pdf-container-product .pagination .current').html(num);
    }

    OnPageRendered(num){

    }

    OnPdfLoaded(num){
        $('.pdf-container-product .pagination .count').html(num);
    }

    get PdfViewer(){
        return this.pdfViewer;
    }

    OpenPopup(){
        $(".pdf-container-product").appendTo(".pdf-popup");
        this.pdfViewer.RenderPage(this.pdfViewer.currentPage);
        this.popup.Open();
    }

    ClosePopup(){
        $(".pdf-container-product").appendTo(".tabs-pdf-wrapper");
        this.pdfViewer.RenderPage(this.pdfViewer.currentPage);
        this.popup.Close();
    }
}

export default PdfProductSpecification;