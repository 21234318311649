import Slider from './Slider';
import $ from 'jquery';

class IndexPreviewSlider extends Slider{
  constructor(container){
    super(container);
    this.breakpoints = {
      default: {
        slides: 1
      },
      md: {
        width: 992,
        slides: 1
      },
      sm:{
        width: 768,
        slides: 1
      },
      xs:{
        width: 450,
        slides: 1
      }
    };
    this.countResponse = $(`${container} .count-text span`);
  }

  Init(){
    super.Init();
    this.SetCountText(1);
    this.targetSlider.on('afterChange', (event, slick) => {
      this.SetCountText(slick.currentSlide + 1);
    });
    this.SetSlideHeight();
  }

  SetSlideHeight() {
    let height = this.targetSlider.find('.slick-track').height();
    this.targetSlider.find('.slick-slide').height(height);
  }

  SetCountText(count){
    this.countResponse.text(count);
  }

  get settings(){
    return Object.assign(super.settings, {
      slidesToShow: this.breakpoints.default.slides,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      adaptiveHeight: false,
      mobileFirst:true,
      pauseOnFocus: true,
      pauseOnHover: true,
      appendDots: '.preview .container-bound.dots-wrapper'
    });
  }
}



export default new IndexPreviewSlider('section.preview');
