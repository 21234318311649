import $ from 'jquery';

$(function () {
  $('.scroll-to-top').on('click',function (e) {
    e.preventDefault();
    $('body, html').animate({scrollTop : 0}, 500);
    return false;
  });

  $(window).scroll(function (e) {
    if (e.currentTarget.pageYOffset >= e.currentTarget.outerHeight) {
      $('.scroll-to-top').css('display', 'block');
    } else {
      $('.scroll-to-top').css('display', 'none');
    }
  });
});