import OffCanvasBg from './OffCanvasBg';
import $ from 'jquery';

class FormSuccessPopup{
    constructor(){
        this.popupSelector = '.form-success.popup';
        this.closeButtonSelector = '.form-success.popup .close-icon';
        this.timeout = null;
    }

    Init() {
        this.popup = new OffCanvasBg(this.popupSelector);

        $(this.closeButtonSelector).on('click', () => {
            if(this.timeout){
                clearTimeout(this.timeout);
            }
            this.popup.Close();
        });
    }


    Open(){
        this.popup.Open();
        // this.timeout = setTimeout(() => {
        //     this.popup.Close();
        // }, 0);
    }
}

export default FormSuccessPopup;