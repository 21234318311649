import Pdf from 'pdfjs-dist/webpack';
import $ from 'jquery';

class PdfViewer{
    constructor(container){
        this.container = container;
        this.canvas = this.container.find('canvas').get(0);
        this.context = this.canvas.getContext('2d');
        this.pdfDoc = null;
        this.pageNum = 1;
        this.pageRendering = false;
        this.pageNumPending = null;
        this.defaultScale = 1;
    }

    get widthContainer(){
        return this.container.width();
    }

    SetUrl(url){
        this.pageNum = 1;
        Pdf.getDocument(url).promise.then((pdfDoc_) => {
            this.pdfDoc = pdfDoc_;
            this.EventPdfLoaded(this.pdfDoc.numPages);
            this.RenderPage(this.pageNum);
        });
    }

    getScale(viewPortWidth){
        return this.widthContainer / viewPortWidth;
    }

    get currentPage(){
        return this.pageNum;
    }


    RenderPage(num){
        this.pageRendering = true;
        this.pdfDoc.getPage(num).then((page) => {
            this.EventPageLoaded(num);
            let viewport = page.getViewport(this.defaultScale);
            let scale = this.getScale(viewport.width);
            viewport = page.getViewport(scale);
            this.canvas.height = viewport.height;
            this.canvas.width = viewport.width;

            let renderContext = {
                canvasContext: this.context,
                viewport: viewport
            };
            let renderTask = page.render(renderContext);

            renderTask.promise.then(() => {
                this.pageRendering = false;
                if (this.pageNumPending !== null) {
                  this.RenderPage(this.pageNumPending);
                  this.pageNumPending = null;
                }
                this.EventPageRendered(num);
            });
        });
    }

    QueueRenderPage(num){
        if (this.pageRendering) {
            this.pageNumPending = num;
        } else {
            this.RenderPage(num);
        }
    }


    //public
    PrevPage() {
        if (this.pageNum <= 1) {
          return;
        }
        this.pageNum--;
        this.QueueRenderPage(this.pageNum);
    }


    NextPage() {
        if (this.pageNum >= this.pdfDoc.numPages) {
            return;
        }
        this.pageNum++;
        this.QueueRenderPage(this.pageNum);
    }


    EventPageLoaded(num){

    }

    EventPageRendered(num){

    }

    EventPdfLoaded(count){

    }
}

export default PdfViewer;
