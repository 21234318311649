import FormPopup from './FormPopup';
import $ from 'jquery';
import ValidateEmpty from './validateForm/ValidateEmpty';
import ValidatePhone from './validateForm/ValidatePhone';
import Validate from './validateForm/Validate';
import ValidateEmail from './validateForm/ValidateEmail';
import FormSuccessPopup from './FormSuccessPopup';

class ProductFormPopup extends FormPopup {
  constructor(){
    super();
    this.popupSelector = '.product-popup';
    this.container = $('.off-canvas-bg.product-popup');
    this.formType = 'product';
  }

  get selectorsShowPopup() {
    return ['.open-product-popup'];
  }

  get inputs() {
    return [
      {
        id: 'name_product_popup',
        name: 'Имя',
        type: 'name',
        validateItems: [
          {
            validate: Validate,
            validateEvent: 'change',
            errorMessage: 'Проверьте введённые данные',
          }
        ]
      },
      {
        id: 'phone_product_popup',
        name: 'Телефон',
        type: 'phone',
        validateItems: [
          {
            validate: ValidatePhone,
            validateEvent: 'change',
            errorMessage: 'Проверьте номер телефона',
          }
        ]
      },
      {
        id: 'comment_product_popup',
        name: 'Имя',
        type: 'comment',
        validateItems: [
          {
            validateEvent: 'change',
            validate: Validate,
          }
        ]
      },
      {
        id: 'email_product_popup',
        name: 'Почта',
        type: 'email',
        validateItems: [
          {
            validate: ValidateEmail,
            errorMessage: 'Проверьте почту',
            validateEvent: 'change',
          }
        ]
      },
    ];
  }

  get otherData(){
    return window.productPopupData ? window.productPopupData : [];
  }

  successHandler(){
    this.popup.Close();
    this.formObserver.ClearInputs();
    let successForm = new FormSuccessPopup();
    successForm.Init();
    successForm.Open();
    $(document).trigger(`form.success.${this.formType}`);
  }
}


export default ProductFormPopup;
