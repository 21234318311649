import $ from 'jquery';
import OffCanvasHeaderOffset from './OffCanvasHeaderOffset';
import OffCanvas from './OffCanvas';

class OffCanvasBg extends OffCanvasHeaderOffset{
    constructor(seletor, top = 0){
        super();
        this.container = $(seletor);
        this.isOpen = false;
        this.topOffset = top;
        this.delayHide = 300;
        this.isBlock = false;
        this.Init();
    }

    Init(){
        if(this.container.length == 0)
            return;
        
        this.container.css("min-height", `calc(100% - ${this.topOffset}px)`);
        this.container.css("top", this.topOffset);
    }

    Open(){
        if(!this.isBlock){
            this.container.addClass("open");
            this.isOpen = true;
            this.FixedContent();
        }
    }


    Close(){
        if(!this.isBlock){
            this.container.removeClass("open");
            this.isBlock = true;
            this.UnFixedContent();
           
            setTimeout(() => {
                this.isBlock = false;
                this.isOpen = false;
            }, this.delayHide);
        }

        this.CloseEvent();
    }


    CloseEvent(){

    }


    Toggle(){
        if(this.isOpen){
            this.Close();
        }
        else{
            this.Open();
        }
    }
}

export default OffCanvasBg;