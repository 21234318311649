import Slider from './Slider';
import $ from 'jquery';

class IndexNewsSlider extends Slider{
  constructor(container){
    super(container);
    this.countResponse = $(`${container} .count-text span`);
    this.widthResponse = 768;
    this.isSlickInit = false;
  }

  Init(){

    this.targetSlider = $(this.targetSliderSelector);
    this.prevBtn = $(this.prevBtnSelector);
    this.nextBtn = $(this.nextBtnSelector);

    this.targetSlider.on('afterChange', (event, slick) => {
      this.SetCountText(slick.currentSlide + 1);
    });

    $(window).on('load resize', this.ToogleSlider.bind(this));

  }


  ToogleSlider(){
    this.targetSlider.not('.slick-initialized').slick(this.settings);
    this.SetCountText(1);
  }

  SetCountText(count){
    this.countResponse.text(count);
  }

  get settings(){
    return Object.assign(super.settings, {
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: this.widthResponse,
          settings: 'unslick'
        }
      ]
    });
  }
}


export default new IndexNewsSlider('section.news-block');