import $ from 'jquery';
import _ from 'lodash';

class VerticalSelectorDynamic{
    constructor(data){
        this.container = $(`#${data.idContainer}`);
        if(this.container.length === 0){
            return;
        }
        this.items = data.items;
        this.animateDelay = 300;
        this.solutionContent = this.container.find(".selector-content");
        this.InitEvents();
        this.SelectItem(this.items[0].id);
        this.timeout = null;
    }

    InitEvents(){
        this.container.on('click', '.selector-item', (e) => {
            let id = e.currentTarget.id;
            this.SelectItem(id);
        });
    }

    SelectItem(id){
        let target = $(`#${id}`);
        let item = _.find(this.items, {'id': id});
        if(target.length === 0){
            throw("Element not found");
        }
        if(item == undefined){
            throw("Item project not found");
        }

        this.UnselectAllItem();
        target.addClass('active');
        this.RenderContent(item);
    }

    UnselectAllItem(){
        this.container.find('.selector-item').removeClass("active");
    }

    RenderContent(item){
        this.solutionContent.removeClass("show");
        if(this.timeout != null){
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.solutionContent.html(item.text);
            this.solutionContent.addClass("show");
        }, this.animateDelay)
    }
}

export default VerticalSelectorDynamic;