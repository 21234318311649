import $ from 'jquery';
import OffCanvasBg from './OffCanvasBg';
import Ymap from 'ymaps';


class MapPopup{
    constructor(data){
        this.apiUrl = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
        this.map = null;
        this.isLoadMap = false;
        this.data = data;
        this.popup = new OffCanvasBg(".map-popup");
        this.container = $(".map-popup");
        this.InitEvent();
    }

    InitEvent(){
        $(".open-map-popup").on('click', e => {
            this.Open();
            e.preventDefault();
        });
        this.container.find(".close-icon").on("click", () => this.Close());
    }

    Open(){
        this.popup.Open();
        if(!this.isLoadMap){
            this.LoadMap();
            this.isLoadMap = true;
        }
    }

    LoadMap(){
        Ymap.load(this.apiUrl).then(m => {
            this.map = new m.Map(this.mapElement, this.settingsMap);
            this.data.placemarks.forEach(e => {
                let mark = this.GetPlacemark(e, m);
                this.map.geoObjects.add(mark);
            });
            this.Loaded();
        });
    }

    get settingsMap(){
        return {
            center: [this.data.lat, this.data.lon],
            zoom: this.data.scale
        }
    }

    get mapElement(){
        return this.container.find(".map-popup-wrapper").get(0);
    }


    GetPlacemark(item, map){
        return new map.Placemark([item.lat, item.lon], {
            balloonContent: item.text
        }, {
            preset: 'islands#nightDotIcon',
        });
    }


    Loaded(){
        this.HideLoader();
    }

    Close(){
        this.popup.Close();
    }

    HideLoader(){
        this.container.find(".loader").css("display", "none");
    }
}


export default MapPopup;