import ProductImageNav from './ProductImageNav';
import _ from 'lodash';
import $ from 'jquery';
import OffCanvas from './OffCanvas';

class ProductImagePopup extends OffCanvas{
    constructor(productCurrent, productNav, items){
        super(".off-product-image");
        this.items = items;
        this.productCurrent = productCurrent;
        this.productNav = new ProductImageNav(productNav, items);
        this.productNav.OnClickNavElement = this.SelectImage.bind(this);
        this.currentId = null;
        this.timeoutOpen = null;
        this.openDelay = 300;

        this.productNav.Init();
        this.InitEvents();
    }

    SelectImage(id){
        this.productNav.UnSelectAll();
        this.productNav.SelectItem(id);
        let src = _.find(this.items, {id_e: id}).srcBig;
        this.productCurrent.attr('src', src);
        this.currentId = id;
    }

    SelectByIndex(index){
        this.SelectImage(this.items[index].id_e);
    }

    PrevImage(){
        let index = this.indexCurrentId;
        let length = this.lengthItems;
        if(index - 1 < 0){
            this.SelectByIndex(length - 1);
        }
        else{
            this.SelectByIndex(index - 1);
        }
    }
    
    NextImage(){
        let index = this.indexCurrentId;
        let length = this.lengthItems;
        if(index + 1 >= length){
            this.SelectByIndex(0);
        }
        else{
            this.SelectByIndex(index + 1);
        }
    }


    Open(){
        $(".off-product-image").addClass("open");
        if(this.timeoutOpen != null){
            clearTimeout(this.timeoutOpen);
        }
        setTimeout(() => {
            this.FixedContent();
        }, this.openDelay);
    }

    Close(){
        $(".off-product-image").removeClass("open");
        this.UnFixedContent();
    }

    InitEvents(){
        $(".off-product-image .arrow-prev").on('click', this.PrevImage.bind(this));
        $(".off-product-image .arrow-next").on('click', this.NextImage.bind(this));
        $(".off-product-image .close-icon").on('click', this.Close.bind(this));
    }

    get indexCurrentId(){
        let currentItem = _.find(this.items, {id_e: this.currentId});
        let index = _.indexOf(this.items, currentItem);
        return index;
    }

    get lengthItems(){
        return this.items.length;
    }
}

export default ProductImagePopup;