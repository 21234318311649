import $ from 'jquery';
import _ from 'lodash';

class GeographySvg{
    constructor(items, selectorSvg){
        this.selectorMap = selectorSvg;
        this.svgMap = null;
        this.ellipse = null;
        this.items = items;


        this.Init();
    }

    Init(){
        this.svgMap = $(this.selectorMap);
        this.ellipse = this.svgMap.find('ellipse');
        this.ellipse.css("transition", "all .3s ease")
        this.items.forEach(e => 
            this.svgMap.find('path')
                .eq(e.position)
                .attr("opacity", 1)
        );
    }

    SelectPath(id){
        this.ellipse.attr("opacity", 1);
        let item = _.find(this.items, {'id': id});
        if(item == undefined){
            throw "Item not found";
        }
        let path = this.svgMap.find('path').get(item.position);
        let pathSize = path.getBBox();

        this.ellipse.attr("cx", pathSize.x + pathSize.width / 2);
        this.ellipse.attr("cy", pathSize.y + pathSize.height / 2);
    }

    SelectPathByPosition(position){
        this.ellipse.attr("opacity", 1);
        let path = this.svgMap.find('path').get(position);
        let pathSize = path.getBBox();

        this.ellipse.attr("cx", pathSize.x + pathSize.width / 2);
        this.ellipse.attr("cy", pathSize.y + pathSize.height / 2);
    }
}

export default GeographySvg;