import $ from 'jquery';

class InputClear{
    constructor(){
        this.clear = $(".input-text .btn-close");
    }

    Init(){
        this.clear.on("click", function(){
            console.log($(this).closest('label').html());
            $(this).siblings('input').val("");
            $(this).closest('label').removeClass('show-field-name')
        });
    }
}

export default new InputClear();