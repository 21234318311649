import '../css/fonts/stylesheet.css';
import '../scss/app.scss';
import '../pug/index.pug';
import '../pug/services.pug';
import '../pug/services_item.pug';
import '../pug/product_sections.pug';
import '../pug/solution_sections.pug';
import '../pug/solution_item.pug';
import '../pug/about.pug';
import '../pug/clients.pug';
import '../pug/clients_item.pug';
import '../pug/news.pug';
import '../pug/news_item.pug';
import '../pug/software.pug';
import '../pug/404.pug';
import '../pug/product_section.pug';
import '../pug/test.pug';
import '../pug/product.pug';
import '../pug/migration.pug';

import '@babel/polyfill';

import 'bootstrap/js/dist/tooltip';

import TextInputObserver from './libs/TextInputObserver';
import SolutionForm from './libs/SolutionForm';
import MobileMenuHeaderButton from './libs/MobileMenuHeaderButton';
import IndexSolutionsSilder from './libs/IndexSolutionsSilder';
import ProjectSelect from './libs/ProjectSelect';
import IndexPressSlider from './libs/IndexPressSlider';
import VerticalSelectorDynamic from './libs/VerticalSelectorDynamic';
import GeographySelector from './libs/GeographySelector';
import HistorySlider from './libs/HistorySlider';
import NewsDetailSlider from './libs/NewsDetailSlider';
import ProductImageBuilder from './libs/ProductImageBuilder';
import ProductTabs from './libs/ProductTabs';
import ServiceSolution from './libs/ServiceSolution';
import GeographySvg from './libs/GeographySvg';
import IndexNewsSlider from './libs/IndexNewsSlider';
import FormFooter from './libs/FormFooter';
import FormPopup from './libs/FormPopup';
import InputClear from './libs/InputClear';
import ProductSolutionsSilder from './libs/ProductSolutionsSilder';
import IndexAdvantagesNumberAnimate from './libs/IndexAdvantagesNumberAnimate';
import MapPopupDebug from './libs/debug/MapPopupDebug';
import MapPopup from './libs/MapPopup';
import PhoneMask from './libs/PhoneMask';
import FormSelectBuilder from './libs/FormSelectBuilder';
import ProductSort from './libs/ProductSort';
import FilterPopup from './libs/FilterPopup';
import FormServiceItem from './libs/FormServiceItem';
import AboutVideo from './libs/AboutVideo';
import Metrika from './libs/Metrika';
import IndexPreviewSlider from './libs/IndexPreviewSlider';
import $ from 'jquery';
import _ from 'lodash';

import './libs/ToTopBtn';
import './libs/HeaderFixed';

import ProjectSelectIndexDebug from './libs/debug/ProjectSelectIndex';
import SolutionSelectItem from './libs/debug/SolutionSelectItem';
import GeographySelectorAbout from './libs/debug/GeographySelectorAbout';
import NewsDetailSliderDebug from './libs/debug/NewsDetailSliderDebug';
import ProductImageDebug from './libs/debug/ProductImageDebug';
import ServiceSolutionDebug from './libs/debug/ServiceSolutionDebug';
import ProductFormPopup from './libs/ProductFormPopup';
import StatTabs from './libs/StatTabs';


if (process.env.NODE_ENV === 'development') {
  new ProjectSelect(ProjectSelectIndexDebug);
  new VerticalSelectorDynamic(SolutionSelectItem);
  new GeographySelector(GeographySelectorAbout);
  new NewsDetailSlider('section.clients-item-page', NewsDetailSliderDebug);
  new NewsDetailSlider('section.news-item-page', NewsDetailSliderDebug);
  new NewsDetailSlider('.product-detail-how-to-work', NewsDetailSliderDebug);
  new ProductImageBuilder(ProductImageDebug);
  new ProductTabs('https://media.readthedocs.org/pdf/arduino-esp8266/docs_to_readthedocs/arduino-esp8266.pdf');
  new StatTabs();
  new ServiceSolution(ServiceSolutionDebug);
  new MapPopup(MapPopupDebug);
} else {
  window.ProjectSelect = ProjectSelect;
  window.VerticalSelectorDynamic = VerticalSelectorDynamic;
  window.GeographySelector = GeographySelector;
  window.NewsDetailSlider = NewsDetailSlider;
  window.ProductImageBuilder = ProductImageBuilder;
  window.ProductTabs = ProductTabs;
  window.StatTabs = StatTabs;
  window.ServiceSolution = ServiceSolution;
  window.GeographySvg = GeographySvg;
  window.MapPopup = MapPopup;
}

window.$ = $;
window.jQuery = $;
window._ = _;

$(function () {
  TextInputObserver.Init();
  SolutionForm.Init();
  MobileMenuHeaderButton.Init();
  IndexSolutionsSilder.Init();
  IndexPressSlider.Init();
  HistorySlider.Init();
  IndexNewsSlider.Init();
  new FormPopup().Init();
  new FormServiceItem().Init();
  new ProductFormPopup().Init();
  FormFooter.Init();
  InputClear.Init();
  ProductSolutionsSilder.Init();
  IndexAdvantagesNumberAnimate.Init();
  FormSelectBuilder.Init();
  ProductSort.Init();
  FilterPopup.Init();
  Metrika.Init();
  PhoneMask.Init();
  IndexPreviewSlider.Init();
  new AboutVideo().Init();
  new StatTabs();

   // $('[data-toggle="tooltip"]').tooltip();
   //   var $ = require("jquery");
   //   var fancybox = require("fancybox")($);
});

