import FormObserver from './FormObserver';
import ValidatePhone from './validateForm/ValidatePhone';
import Validate from './validateForm/Validate';
import ValidateEmpty from './validateForm/ValidateEmpty';
import FormSuccessPopup from './FormSuccessPopup';
import FormObserverPhoneOrEmail from './FormObserverPhoneOrEmail';
import ValidateEmail from './validateForm/ValidateEmail';
import $ from 'jquery';

class FormFooter{
    constructor(){
        this.container = $("section.footer-callback");
        this.formObserver = new FormObserverPhoneOrEmail(this.inputs, this.otherData);
        this.formType = 'footer';
    }


    Init(){
        this.formObserver.ClearInputs();
        this.formObserver.InitEvents();
        this.formObserver.Success = () => {
            this.successHandler();
        }
        this.container.find('.submit').on('click', () => this.formObserver.SendData());
        this.container.find('.clear').on('click', () =>  this.formObserver.ClearInputs());
    }


    get inputs() {
        return [
            {
                id: "name_footer",
                name: "Имя",
                type: "name",
                validateItems: [
                    {
                        validate: Validate,
                        validateEvent: "change",
                        errorMessage: "Проверьте введённые данные",
                    }
                ]
            },
            {
                id: "phone_footer",
                name: "Телефон",
                type: "phone",
                validateItems: [
                    {
                        validate: ValidatePhone,
                        validateEvent: "change",
                        errorMessage: "Проверьте номер телефона",
                    }
                ]
            },
            {
                id: "comment_footer",
                name: "Имя",
                type: "comment",
                validateItems: [
                    {
                        validateEvent: "change",
                        validate: Validate,
                    }
                ]
            },
            {
                id: 'email_footer',
                name: 'Почта',
                type: 'email',
                validateItems: [
                  {
                    validate: ValidateEmail,
                    errorMessage: 'Проверьте почту',
                    validateEvent: 'change',
                  }
                ]
            },
        ]
    }

    get otherData(){
        return [
            {id: "url", value: this.currentUrl, name: "Url", type: "url"}
        ];
    }

    get currentUrl(){
        return window.location.origin + window.location.pathname;
    }


    successHandler(){
        this.formObserver.ClearInputs();
        let successForm = new FormSuccessPopup();
        successForm.Init();

        if (this.formObserver.successMessage) {
            $(successForm.popupSelector).find('.message-subtitle').after('<div class="message-subtitle success">' + this.formObserver.successMessage + '</div>');
        }

        successForm.Open();
        $(document).trigger(`form.success.${this.formType}`);
    }
}


export default new FormFooter();