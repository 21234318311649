import Validate from './Validate';

class ValidatePhone extends Validate {
    constructor(data) {
        super(data);
        this.pattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/ig;
    }

    get IsValidate() {
        return this.data.match(this.pattern) !== null && this.data.length > 0;
    }
}

export default ValidatePhone;
