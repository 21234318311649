import ProductImageNav from './ProductImageNav';
import _ from 'lodash';
import $ from 'jquery';

class ProductImageDetail{
    constructor(productCurrent, productNav, items, popup){
        this.items = items;
        this.popup = popup;
        this.productCurrent = productCurrent;
        this.productNav = new ProductImageNav(productNav, items);
        this.productNav.OnClickNavElement = this.SelectImage.bind(this);
        this.productNav.OnRenderList = this.SelectFirst.bind(this);
        this.currentId = null;
        this.productNav.Init();
        this.InitEvents();
    }

    SelectImage(id){
        this.productNav.UnSelectAll();
        this.productNav.SelectItem(id);
        let src = _.find(this.items, {id_e: id}).src;
        this.productCurrent.attr('src', src);
        this.currentId = id;
    }

    SelectFirst(){
        let id = this.items[0].id_e;
        this.SelectImage(id);
    }
    
    

    InitEvents(){
        $("section.product-detail-page .maximize-image").on('click', () => {
            this.popup.Open();
            this.popup.SelectByIndex(this.indexCurrentId);
        });
    }

    get indexCurrentId(){
        let currentItem = _.find(this.items, {id_e: this.currentId});
        let index = _.indexOf(this.items, currentItem);
        return index;
    }
}

export default ProductImageDetail;