import MobileMenu from './MobileMenu';
import $ from 'jquery';


class MobileMenuHeaderButton{
    constructor(){
        this.selector = ".mobile-menu-btn";
    }

    Init(){
        $(this.selector).on('click', () => MobileMenu.Toggle());
        $(document).on('mobileMenuOpen', () => $(this.selector).addClass("open"));
        $(document).on('mobileMenuClose', () => $(this.selector).removeClass("open"));
    }
}

export default new MobileMenuHeaderButton();